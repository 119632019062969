import React from "react";
import { withPrefix } from "gatsby";
import { Container, Button } from "react-bootstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <section className="notFoundPage">
      <Container className="notFoundPage__404" fluid>
        <h1>404</h1>
        <h3>Страница не найдена</h3>
        <Button variant="danger" href={withPrefix("/")}>
          Вернуться на главную
        </Button>
      </Container>
    </section>
  </Layout>
);

export default NotFoundPage;
